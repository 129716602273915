//~ Import modules
import './Style/main.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Circle, Hexa, Pattern, Badges, Elephant } from 'Components';
import { ReactComponent as KanjiSVG } from './media/yumi-kanji.svg';
import social from '../../Store/__fake_data__/social.json';
import profile from '../../Assets/media/profil-3.png';

//~ Component
const Contact = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'end end'] });
  const opacity = useTransform(scrollYProgress, [0, 0.85], [0, 1]);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <section ref={ref} id="contact" className="contact section">
      <div className="contact__container noselect">
        <div className="contact__picture-container">
          <Pattern property="plain" section="contact" />
          <Pattern property="textured" section="contact" />
          <Elephant section="contact" />

          <motion.img
            className="contact__picture-container__profile noselect"
            src={profile}
            alt="profile picture"
            style={{ opacity }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          <div className={`kanji kanji__text ${isHovered ? 'kanji__text--out' : ''} noselect`}>
            <p>Yumi written with these kanji means help, protect and go far to an even greater degree.</p>
            <p>So, as far as I can go, I’m going to try and help you out.</p>
            <p>Let’s go!</p>
          </div>
          <KanjiSVG className={`kanji kanji__svg ${isHovered ? 'kanji__svg--out' : ''} noselect`} />
        </div>

        <div className="contact__content">
          <div className="contact__content__main">
            <Circle section="contact" property="textured" />
            <h3 className="contact__content__main-title">
              <span>GET IN</span>
              <span>TOUCH</span>
            </h3>
            <p className="contact__content__text">Do you have an idea, a project you need help with, a question ?</p>
            <p className="contact__content__text">Let's talk! </p>
            <a href="mailto:contact@yumicode.net?subject=Contact%20from%20Portfolio!" className="contact__content__text__mail">
              contact@yumicode.net{' '}
            </a>

            <div className="contact__content__infos__container">
              <div className="contact__content__infos__badges">
                {social.map((badge) => (
                  <Badges key={uuidv4()} badge={badge} />
                ))}
              </div>
              <a href="mailto:contact@yumicode.net?subject=Contact%20from%20Portfolio!" className="contact__content__infos__mail">
                contact@yumicode.net ―{' '}
              </a>
            </div>
          </div>
        </div>
      </div>

      <Hexa section="contact" />
      <Hexa section="contact" />
      <Hexa section="contact" />
      <Hexa section="contact" />
      {/* <span className="contact__semicolon">;</span> */}
    </section>
  );
};

export default Contact;
