//~ Import modules
import './NoPage.scss';
import { HexaGrid, Error404 } from 'Components';

//~ Component
const NoPage = () => {
  return (
    <section className="nopage section">
      <HexaGrid section='nopage'/>
      <Error404/>
    </section>
  );
};

export default NoPage;
