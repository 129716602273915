//~ Import modules
import './Home.scss';
import { motion } from 'framer-motion';
import { About, Projects, Skills, Welcome, Contact } from '../index';
import { ANIMATION_ONLEAVE } from 'Utils/Animations/FramerMotion';

//~ Component
const Home = () => {
  return (
    <motion.section className="home" {...ANIMATION_ONLEAVE}>
      <Welcome />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </motion.section>
  );
};

export default Home;
