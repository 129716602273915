//~ Import module
import './Awwwards.scss';
import { ReactComponent as AwwwardsSVG } from './Awwwards.svg';

//~ Component
const Awwwards = () => {
  return (
    <div className="awwwards">
      <a href="https://www.awwwards.com/sites/yumicode" target="_blank">
        <AwwwardsSVG />
      </a>
    </div>
  );
};

export default Awwwards;
