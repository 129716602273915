//~Import modules
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = 'http://localhost:4200/api/v1';

const options = {
    reducerPath: 'apiYumelio',
    baseQuery: fetchBaseQuery({
      baseUrl,
      // to send back the cookies
      credentials: 'include',
    //   prepareHeaders
    }),
  
    //RTK Query lets us define relationships between queries and mutations to enable automatic data refetching, using "tags"
    tagTypes: [],
  
    endpoints: (build : any) => ({
        getHomePage: build.query({
            query: () => ({
                url: '/',
                // validateStatus: (response, result) => response.status === 200 && !result.isError,
            })
        })
    }),
};
  
//~ Export 
export const yumelioApiSlice = createApi(options);
export const { useGetHomePageQuery } = yumelioApiSlice;
export default yumelioApiSlice.reducer;