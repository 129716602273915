//~ Import modules
import './SkillsGraph.scss';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ANIMATION_ONLEAVE } from 'Utils/Animations/FramerMotion';
import { Graph } from 'Components';
import { useEffect } from 'react';

//~ Component
const SkillsGraph = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/skillsgraph') window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <motion.section id="skills-graph" className="skills-graph section" {...ANIMATION_ONLEAVE}>
      <Graph />
    </motion.section>
  );
};

export default SkillsGraph;
