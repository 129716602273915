//~ Import modules
import './Header.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeBtn, Navbar, Sidebar, Awwwards } from 'Components';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Header = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const location = useLocation();
  return location.pathname === '/' ? (
    <header className={`header theme--${theme}`}>
      <Awwwards/>
      <ThemeBtn />
      <Navbar />
      <Sidebar section="header" />
    </header>
  ) : (
    <></>
  );
};

export default Header;
