//~ Imports modules
import './Footer.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Footer = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  const location = useLocation();
  const isNotHomePage = location.pathname !== '/';

  return (
    <footer className={`footer noselect theme--${theme} ${isNotHomePage ? 'footer--left' : ''}`}>
      <p className="footer__copyright">Copyright © {currentYear} - Yumicode. </p>
      <p className="footer__copyright">All Rights Reserved -{<Link to="/legal">Legal notices</Link>}</p>
      {/* <p>{t('footer_rights')}.</p> */}
    </footer>
  );
};

export default Footer;
