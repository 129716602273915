//~ Import modules
import { Route, Navigate } from 'react-router-dom';
import { Home, NoPage, About, Contact, Projects, Skills, SkillsGraph, Playground, Legal } from '../Pages';

const Router = [
  { id: 1, isNav: false, name: 'Home', mainPath: '/', mainElement: <Home /> },
  { id: 2, isNav: false, name: 'NoPage', mainPath: '/404', mainElement: <NoPage /> },
  { id: 3, isNavHome: true, name: 'About', mainPath: '/about', mainElement: <About /> },
  { id: 4, isNavHome: true, name: 'Skills', mainPath: '/skills', mainElement: <Skills /> },
  { id: 5, isNavHome: true, name: 'Projects', mainPath: '/projects', mainElement: <Projects /> },
  { id: 6, isNav: false, name: 'Playground', mainPath: '/playground', mainElement: <Playground /> },
  { id: 7, isNavHome: true, name: 'Contact', mainPath: '/contact', mainElement: <Contact /> },
  { id: 8, isNav: false, name: 'Graph', mainPath: '/skillsgraph', mainElement: <SkillsGraph /> },
  { id: 9, isNav: false, name: 'NoPage', mainPath: '/*', mainElement: <Navigate to="/404" /> },
  { id: 10, isNav: false, name: 'Legal', mainPath: '/legal', mainElement: <Legal /> },
  //   { id: 8, isNav: true, name: i18n.t('route_name_login'), mainPath: '/login', mainElement: <Login /> },
];

const mainRoutes = Router.map(({ id, mainPath, mainElement }) => <Route key={id} path={mainPath} element={mainElement} />);

export { Router, mainRoutes };
