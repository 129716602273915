//~ Import modules
import './Welcome.scss';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, useScroll, useTransform, useInView } from 'framer-motion';
import { Portrait, RedHexa, Dots, Circle } from 'Components';
import { ANIMATION_OPACITY_INVIEW } from 'Utils/Animations/FramerMotion';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Welcome = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const animation = isInView ? 'welcome__main-title__line--1--animation' : '';

  const { scrollYProgress } = useScroll({ target: ref, offset: ['start start', 'end start'] });
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const x = useTransform(scrollYProgress, [0, 1], [0, -100]);

  const optionsRedHexa = {
    number: 3,
    size: 'medium',
    direction: 'column',
  };

  return (
    <motion.section id="welcome" className="welcome section" ref={ref} initial={{ opacity: 0 }} animate={{ opacity: 1, transition:{duration: 4} }}>
      <Dots section={'welcome'} />
      <Dots section={'welcome'} />
      <Dots section={'welcome'} />

      <Circle property="bordered" section="welcome" />
      <Circle property="bordered" section="welcome" />

      <motion.div className="welcome__red-hexa" style={{ opacity }}>
        <RedHexa {...optionsRedHexa} />
      </motion.div>

      <div className={`welcome__container`}>
        {/* LEFT CONTAINER */}
        <div className="welcome__portrait-container">
          <Portrait />
        </div>

        {/* RIGHT CONTAINER */}
        <div className={`welcome__text-container theme--${theme}`}>
          <motion.p className={`welcome__text`} {...ANIMATION_OPACITY_INVIEW}>
            Hi there ! I'm Yumi.
          </motion.p>
          <h1 className={`welcome__main-title__line welcome__main-title__line--1 ${animation} noselect`}>Portfolio</h1>
          <motion.p className={`welcome__main-title__line welcome__main-title__line--2`} {...ANIMATION_OPACITY_INVIEW}>
            of a Creative Freelancer & Fullstack Developer ―
          </motion.p>
        </div>
      </div>
    </motion.section>
  );
};

export default Welcome;
