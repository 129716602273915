//~ Import modules
import './Layout.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MotionValue, useScroll } from 'framer-motion';
import { mousePosition, parallaxEffectOnMouseMove } from '../Store/Reducers/Cursor';
import { setTargetScreen, setInnerWidth } from '../Store/Reducers/Media';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Logo, ArrowUp, Cursor } from 'Components';
import { State } from '../../Types/GlobalTypes';
import { ScrollYProps } from '../../Types/GlobalTypes/FramerMotionTypes';

//~ Component
const Layout = () => {
  const location = useLocation();
  const layoutRef = useRef(null);
  const { scrollYProgress }: ScrollYProps = useScroll({ target: layoutRef });

  const theme = useSelector((state: State) => state.theme?.default);
  const defaultMediaScreen = useSelector((state: State) => state.media?.defaultMediaScreen);

  // cursor tracker
  const dispatch = useDispatch();
  const handleMove = (event: any) => {
    let x = event.clientX;
    let y = event.clientY;

    dispatch(mousePosition({ x, y }));
    dispatch(parallaxEffectOnMouseMove());
    // dispatch(customCursor());
  };
  
  // viewport size
  useEffect(() => {
    const handleResize = () => {
      dispatch(setTargetScreen());
      dispatch(setInnerWidth());
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`layout theme--${theme} layout__background--${theme}`} ref={layoutRef} onMouseMove={handleMove}>
      <main className={`layout__main theme--${theme}`}>
        <Header />
        <Logo key={uuidv4()} />
        <Outlet />
      </main>

      {location.pathname === '/' && <ArrowUp scrollYProgress={scrollYProgress} />}
      <Footer />
      {defaultMediaScreen === 'desktop' && <Cursor />}
    </div>
  );
};

export default Layout;
