//~ Import modules
import './Style/main.scss';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'framer-motion';
import { Slider, Circle, Dots } from 'Components';
import { ReactComponent as SkillsSVG } from './SVG/skills.svg';
import { State } from '../../../Types/GlobalTypes';

//~ Component
const Skills = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <section id="skills" className="skills section" ref={ref}>
      <h1 className="main__title skills__main-title">Skills </h1>
      <SkillsSVG className={`skills__banner theme--${theme} ${isInView ? 'skills__banner--animation' : ''}`} />
      <Slider />
      <Circle property="bordered" section="skills" />
      <Dots section={'skills'} />
    </section>
  );
};

export default Skills;
