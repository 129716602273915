//~ Import modules
import './Legal.scss';
import { Fragment } from 'react';
import LegalData from './LegalDataEN.json';

//~ Component
const Legal = () => {
  return (
    <section className="legal section">
      {LegalData.map((data) => (
        <Fragment key={data.id}>
          {data.id === 1 ? <h1 className='legal__main-title'>{data.title}</h1> : <h2 className='legal__title'>{data.title}</h2>}

          {data.description.map((descriptionData) => (
            <p className='legal__text'>{descriptionData}</p>
          ))}
        </Fragment>
      ))}
    </section>
  );
};

export default Legal;
