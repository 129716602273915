//~ Import modules
import './About.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ANIMATION_TRANSLATE_DOWN } from 'Utils/Animations/FramerMotion';
import { Gingko, Card, Circle } from 'Components';
import articles from '../../Store/__fake_data__/articles.json';
import profile from '../../Assets/media/profil-bw-noise.png';
import { State } from '../../../Types/GlobalTypes';

const About = () => {
  const theme = useSelector((state: State) => state.theme?.default);
  const defaultMediaScreen = useSelector((state: State) => state.media?.defaultMediaScreen);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'end end'] });
  const translateX1 = useTransform(scrollYProgress, [0, 0.85], [-1500, 0]);
  const opacity1 = useTransform(scrollYProgress, [0, 0.85], [0, 1]);
  const opacity2 = useTransform(scrollYProgress, [0, 0.5], [0, 1]);

  return (
    <section ref={ref} id="about" className="about section">
      <Circle section="about" property="bordered" />

      <Circle section="about" property="textured" />

      <motion.div
        className={`about__text-container theme--${theme}`}
        initial={{}}
        animate={{ transition: { type: 'spring', stiffness: 100, damping: 30, restDelta: 0.001 } }}
        style={{ x: translateX1 }}
      >
        <motion.h1 className="main__title about__main-title">― About me</motion.h1>

        <motion.div className={`about__text-content theme--${theme}`}>
          {articles.map((card: any) => card.section === 'about' && <Card card={card} key={uuidv4()} />)}

          <motion.p className={`about__main-title__line about__punchline`} {...ANIMATION_TRANSLATE_DOWN}>
            Let's work together !
          </motion.p>
        </motion.div>

        <Gingko section={'about'} />
        <Gingko section={'about'} />
        <Gingko section={'about'} />
        <Gingko section={'about'} />
      </motion.div>

      <div className="about__picture-container">
        <Gingko section={'about'} />
        <Gingko section={'about'} />
        <Gingko section={'about'} />
        <Gingko section={'about'} />

        <motion.img
          className="about__picture-container__profile noselect"
          src={profile}
          alt="profile picture"
          style={{ opacity: defaultMediaScreen === 'desktop' ? opacity1 : opacity2 }}
        />
      </div>
    </section>
  );
};

export default About;
