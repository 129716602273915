//~ Import modules
import './Playground.scss';
import { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ANIMATION_ONLEAVE } from 'Utils/Animations/FramerMotion';
import { Box, Hexa, Portrait, Graph, RedHexa } from 'Components';

//~ Component
const Playground = () => {
  const ref = useRef(null);

  // handle parallax
  const { scrollYProgress } = useScroll({ target: ref, offset: ['end end', 'end start'] });
  const { scrollY } = useScroll();
  const width = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);

  const y0 = useTransform(scrollY, [0, 2000], ['0px', '250px']);
  const y1 = useTransform(scrollYProgress, [0, 1], [-10, 200]);
  const y2 = useTransform(scrollYProgress, [0, 1], [-10, -400]);

  return (
    <motion.section className="playground" {...ANIMATION_ONLEAVE}>
      <h1 className="main__title">Playground</h1>

      <Graph />
      <Portrait />

      <Hexa />
      <RedHexa number={3} size={'medium'} direction={'column'} />

      <Box />
      <Box />
      <Box />

      <section ref={ref} style={{ background: 'grey', opacity: 0.5 }}>
        <motion.div className="box" style={{ y: y0, x: -50, background: 'teal' }} />

        <motion.div className="box" style={{ width, background: 'pink' }} />
      </section>

      <motion.div className="box" style={{ y: y1, x: -50 }} />

      <motion.div className="box" style={{ y: y2, x: 5, background: 'salmon' }} />
    </motion.section>
  );
};

export default Playground;
