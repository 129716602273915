//~ Import modules
import './Style/main.scss';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Circle, Cloud, Pattern, ProjectCard, Binary, Spinner } from 'Components';
import projects from '../../Store/__fake_data__/projects.json';
import { ReactComponent as ProjectBG } from './background/projects-bg.svg';

//~ Component
const Projects = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const animation = isInView ? 'projects__main-title--animation' : '';

  const WORD_TO_ANIMATE = 'Projects';
  const MAIN_INTRO = projects.find((project) => project.name === 'main-intro');

  return (
    <section ref={ref} id="projects" className="projects section">
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />
      <Cloud section="projects" />

      <section className="projects__main-container">
        <Binary section="projects" />
        <Binary section="projects" />
        <Circle section="projects" property="bordered" />
        <Circle section="projects" property="bordered" />
        <Pattern property="plain" section="projects" />
        <Pattern property="plain" section="projects" />

        <header className="projects__main-container__header">
          <ProjectBG className={`main__title projects__main-title--background`} />

          <motion.h1 className={`main__title projects__main-title ${animation}`}>
            -
            {[...WORD_TO_ANIMATE].map((letter, index) => (
              <motion.span className={`blink`} key={uuidv4()}>
                {letter}
              </motion.span>
            ))}
            -
          </motion.h1>
          <p className="projects__main-container__header__text">{MAIN_INTRO?.paragraph ? MAIN_INTRO.paragraph : <Spinner />}</p>
        </header>

        {projects.map((card, index) => card.section === 'projects' && <ProjectCard key={uuidv4()} card={card} index={index + 1} />)}
      </section>
    </section>
  );
};

export default Projects;
