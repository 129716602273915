//& Import modules
import './Cursor.scss';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

//~ Component
const Cursor = () => {
  let { x, y } = useSelector((state: any) => state.cursor);

  //if add circles, add css too
  const NB_OF_CIRCLES = 28;
  const GAP_CENTERING_CURSOR = 13;
  let variants = { initial: {}, animate: {} };
  let circles: any[] = [];

  for (let index = 0; index < NB_OF_CIRCLES; index++) {
    const DELAY_IN_SECONDS = ((index + 3) / 4000) * 0.5;
    // framer motion properties on cursor
    variants = {
      initial: {
        // x: x - (GAP_CENTERING_CURSOR - index * 0.1),
        // y: y - (GAP_CENTERING_CURSOR - index * 0.2),
        x: x,
        y: y,
        transition: {
          delay: 0,
        },
      },
      animate: {
        x: x - (GAP_CENTERING_CURSOR - index * 0.2),
        y: y - (GAP_CENTERING_CURSOR - index * 0.2),
        transition: {
          delay: DELAY_IN_SECONDS,
        },
      },
    };

    circles.push(<motion.div className={`circle circle-${index}`} variants={variants} animate="animate" />);
  }

  return <div className={`circle__container`}>{circles.map((circle) => circle)}</div>;
};

export default Cursor;
