//~ Import modules
import './App.scss';
import '../Assets/i18n';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { mainRoutes } from '../Routes/Routes';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../Layout/Layout';
import Loader from 'Components/Loader/Loader';
import { ANIMATION_LOADER, ANIMATION_BACKGROUND, ANIMATION_LAYOUT } from 'Utils/Animations/FramerMotion';

//~ Component
const App = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useSelector((state: any) => state.theme.default);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(!isLoading), 4100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      {isLoading ? (
        <motion.div {...ANIMATION_BACKGROUND} className={`loader__container__background theme--${theme}`}>
          {/* animate when exit from the component tree */}
          <motion.div {...ANIMATION_LAYOUT}>
            <motion.div {...ANIMATION_LOADER}>
              <Loader />
            </motion.div>
          </motion.div>
        </motion.div>
      ) : (
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Layout />}>
            {mainRoutes}
            {/* {articleRoute} */}
          </Route>
        </Routes>
      )}
    </AnimatePresence>
  );
};

export default App;
